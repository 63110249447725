import React from "react";

const Days = ({ hook, isWeekend }) => {
  return (
    <>
      {[...Array(isWeekend ? 3 : 4)].map((e, i) => {
        return (
          <td
            key={"F" + i}
            className={`day ${hook.isDateOutOfRange() ? "disabled" : ""} ${hook.isCurrentDate() ? "today" : ""}`}
          >
            {hook.printDateTracker()}
          </td>
        );
      })}
    </>
  );
};

export default Days;
