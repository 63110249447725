import React, { useState, useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer, Loading, TitleBar } from "Components";
import { AppContext } from "Contexts/AppContext/AppContext";
import ImageViewer from "react-simple-image-viewer";

function PicturesScreen() {
  const { chaletID = "All", propertyID } = useParams();
  const chaletTitle = `Karam's Chalet ${propertyID} ${chaletID}`;

  const context = useContext(AppContext);
  const allChalets = context.getData();
  const navigate = useNavigate();
  const currentChalet = allChalets?.find((e) => {
    return e.key === chaletID;
  });
  const imagesArr = currentChalet?.images[propertyID];
  useEffect(() => {
    if (!currentChalet && allChalets && imagesArr?.length === 0) {
      navigate("/");
    }
  }, [allChalets, chaletID, currentChalet, imagesArr?.length, navigate, propertyID]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (allChalets) {
    const heightOrWidth =
      window.matchMedia && window.matchMedia("(max-width: 480px)").matches ? { width: 330 } : { height: 350 };
    return (
      <div style={{ flex: 1 }}>
        <TitleBar title={chaletTitle} />
        <div className="PictureScreenContainer">
          {imagesArr?.map((src, index) => (
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              key={index}
              style={{ margin: "2px" }}
              alt=""
              {...heightOrWidth}
            />
          ))}

          {isViewerOpen && (
            <ImageViewer
              src={imagesArr || []}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
        </div>
        <Footer whichChalet={chaletID} />
      </div>
    );
  }
  return <Loading />;
}

export default PicturesScreen;
