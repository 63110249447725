import React from "react";
import { Link } from "react-router-dom";

const TitleBar = ({ title }) => {
  return (
    <div className="TitleBarContainer">
      <Link to="/" style={{ color: "white", textDecoration: "none" }}>
        <h1>&nbsp;&nbsp;&nbsp;&nbsp;{title}&nbsp;&nbsp;&nbsp;&nbsp;</h1>
      </Link>
    </div>
  );
};

export { TitleBar };
