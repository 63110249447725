import "./styles/reduction.scss";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { HomeScreen, ChaletScreen, PicturesScreen, PageNotFound } from "./Screens";
import { AppContextProvider } from "Contexts";
import "./globals";
import SurveyScreen from "Screens/SurveyScreen/SurveyScreen";
import ContactUsScreen from "Screens/ContactUs/ContactUsScreen";

function App() {
  return (
    <AppContextProvider>
      <div className="App">
        <div className="router-content">
          <Router>
            <Routes>
              <Route path="/Chalet">
                <Route path=":chaletID" element={<ChaletScreen />} />
                <Route path="" element={<ChaletScreen />} />
              </Route>
              <Route path="/Survey/:surveyID" element={<SurveyScreen />} />
              <Route path="/contact-us" element={<ContactUsScreen />} />
              <Route path="Pictures/:chaletID/:propertyID" element={<PicturesScreen />} />
              <Route path="/" element={<HomeScreen />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="/privacy.html" element={<Navigate to="/privacy.html" replace />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
          </Router>
        </div>
      </div>
    </AppContextProvider>
  );
}

export default App;
