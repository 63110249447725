import instance from "./apiConfiguration";

instance.interceptors.request.use(
  async (request) => {
    global.printApiResponse("Request Interceptor Success", JSON.stringify(request));
    // const [authToken, notificationToken, language] = await Promise.all([
    //   AsyncStorage.getItem(STORAGE_AUTHORIZATION_TOKEN),
    //   AsyncStorage.getItem(STORAGE_NOTIFICATION_TOKEN),
    //   AsyncStorage.getItem(STORAGE_LANGUAGE_MODE),
    // ]);
    // const version = Constants.manifest.version;
    // request.headers.common["Authorization"] = `Bearer ${authToken}`;
    // request.headers.common["NotificationToken"] = notificationToken;
    // request.headers.common["AppVersion"] = version;
    // request.headers.common["Platform"] = Platform.OS ?? "Test";
    // request.headers.common["Language"] = language ?? "English";

    return request;
  },
  (error) => {
    // printApiError("Request Interceptor Error");
    return Promise.reject(error);
  }
);
