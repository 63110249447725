export const Actions = {
  SET_ALERT_DATA: "SET_ALERT_DATA",
  SET_SHOW_PICTURES_MODAL: "SET_SHOW_PICTURES_MODAL",
  SET_DATA: "SET_DATA",
  SET_USER_DATA: "SET_USER_DATA",
  SET_THEME_MODE: "SET_THEME_MODE",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_API_ERROR: "SET_API_ERROR",
  SET_MEMBERS_LIST: "SET_MEMBERS_LIST",
  SET_NOTIFICATION_LIST: "SET_NOTIFICATION_LIST",
  SET_ERROR: "SET_ERROR",
  SET_PERSONAL_DATA: "SET_PERSONAL_DATA",
  SET_CONTACTS_DATA: "SET_CONTACTS_DATA",
};
