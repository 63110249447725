import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";

const Body = ({ children, chaletID, chaletBackground = require("../../../Images/SkyBackgroundImage.jpg") }) => {
  const BodyBackground = styled.div`
    &::before {
      background-image: url(${chaletBackground});
      background-size: cover;
      content: "";
      position: fixed;
      left: 0;
      right: 0;
      z-index: -1;
      display: block;
      height: 100%;
      z-index: -1;
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
      margin-top: -100px;
      transform: scale(1.1);
    }
  `;

  return (
    <BodyBackground>
      <Row className={`justify-content-center g-0`}>
        <Col lg="5" md="11" sm="11" xs="11">
          {children}
        </Col>
      </Row>
    </BodyBackground>
  );
};
export default Body;
