import React from "react";

function PageNotFound() {
  return (
    <div style={{ flex: 1 }} className="background404">
      <h1>Error 404</h1>
      <h2>Page Not Found</h2>
    </div>
  );
}

export default PageNotFound;
