import React from "react";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const CustomerComments = ({ comments }) => {
  if (!comments || comments.length === 0) {
    return <div />;
  }
  return (
    <div className="CustomerCommentContainer">
      <h4 className="title">Customers' Comments</h4>
      {shuffleArray(comments)
        .filter((_, idx) => idx < 5)
        .map((comment) => {
          return <Comment key={comment.comment} comment={comment.comment} chalet={comment.chalet} />;
        })}
    </div>
  );
};

const Comment = ({ comment, chalet }) => {
  return (
    <div className="card">
      <p>{comment}</p>
      <cite>- {chalet}</cite>
    </div>
  );
};
export default CustomerComments;
