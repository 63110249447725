import React from "react";
const Icon = ({ iconClass, url }) => {
  return (
    <a href={url} rel="_">
      <span hidden>{iconClass}</span>
      <i
        className={iconClass}
        style={{
          color: "white",
          fontSize: 36,
          marginRight: 5,
          marginLeft: 5,
        }}
        aria-hidden="true"
      />
    </a>
  );
};

export { Icon };
