export const initState = {
  userData: null,
  data: null,
  alert: null,
  picturesModal: null,
  theme: "dark",
  isLoading: false,
  members: [],
  notifications: [],
  contactsData: [],
  errorMessage: null,
  errorTitle: null,
  personalData: {
    myInformation: {
      id: 1,
      name: "",
      paid: 0,
      leftAmountToPay: 0,
      extraAmount: 0,
      shouldPay: false,
      subscriptionBase: {
        id: 1,
        Monthly: 0,
        SubscriptionFees: 0,
        SubscriptionFeesPaid: 0,
        shouldPayFees: false,
        userId: 1,
        leftAmountToPay: 0,
      },
    },
    notificationHistory: [],
  },
};
