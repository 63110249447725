import React, { useContext } from "react";
import moment from "moment";
import Availability from "./Availability";
import DateControllerWithTitle from "./DateControllerWithTitle";
import Days from "./Days";
import DateHook from "./DateHook";
import { AppContext } from "Contexts";

export default function DateBodySection({ chalet, isBoth }) {
  const myHook = DateHook();
  const context = useContext(AppContext);
  const getAvailable = (startDate, isWeekend = false) => {
    if (startDate.isSameOrBefore(moment())) {
      return "";
    }

    const endDate = startDate.clone().add(isWeekend ? 2 : 3, "days");
    var properties = [...chalet.properties];

    const booked = chalet.booked;

    for (let i = 0; i < booked.length; i++) {
      const fromDate = moment(booked[i].fromDate, "DD/MM/YYYY");
      const toDate = moment(booked[i].toDate, "DD/MM/YYYY");
      if (startDate.isBetween(fromDate, toDate, "days", "[]") || endDate.isBetween(fromDate, toDate, "days", "[]")) {
        properties = properties.filter((val) => !booked[i].properties.includes(val));
      }
    }
    return properties.join("");
  };
  const getAvailableByKey = (startDate, chalet, isWeekend = false) => {
    if (startDate.isSameOrBefore(moment())) {
      return "";
    }
    const endDate = startDate.clone().add(isWeekend ? 2 : 3, "days");
    var properties = [...chalet.properties];

    const booked = chalet.booked;

    for (let i = 0; i < booked.length; i++) {
      const fromDate = moment(booked[i].fromDate, "DD/MM/YYYY");
      const toDate = moment(booked[i].toDate, "DD/MM/YYYY");
      if (startDate.isBetween(fromDate, toDate, "days", "[]") || endDate.isBetween(fromDate, toDate, "days", "[]")) {
        properties = properties.filter((val) => !booked[i].properties.includes(val));
      }
    }
    return properties.join("");
  };
  return (
    <>
      <DateControllerWithTitle dateController={myHook} />
      <table className="DateBodyContainer">
        <thead>
          <tr>
            <th className="day">Sun</th>
            <th className="day">Mon</th>
            <th className="day">Tue</th>
            <th className="day">Wed</th>
            <th className="day">Thu</th>
            <th className="day">Fri</th>
            <th className="day">Sat</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(myHook.numOfDaysBetweenFirstAndLastDates)].map((e, i) => {
            const entryPoints = myHook.getEntryPoints;
            const { weekday, weekend } = entryPoints[i];
            return (
              <React.Fragment key={"A" + i}>
                <tr>
                  <Days hook={myHook} />
                  <Days isWeekend hook={myHook} />
                </tr>
                {isBoth ? (
                  context.getData()?.map((chalet) => {
                    if (chalet.key !== "All") {
                      if (chalet.temporaryUnavailable !== 1)
                        return (
                          <tr>
                            <Availability
                              key={chalet.key + weekday.format("DD/MM/YYYY")}
                              available={getAvailableByKey(weekday, chalet)}
                              start={weekday}
                              end={weekend}
                              chaletName={chalet.key}
                              arabicChaletName={chalet.name}
                              isBoth
                            />
                            <Availability
                              key={chalet.key + weekend.format("DD/MM/YYYY")}
                              isWeekend
                              available={getAvailableByKey(weekend, chalet, true)}
                              start={weekend}
                              end={weekend.clone().add(3, "days")}
                              chaletName={chalet.key}
                              arabicChaletName={chalet.name}
                              isBoth
                            />
                          </tr>
                        );
                    } else {
                      return <></>;
                    }
                  })
                ) : (
                  <tr>
                    <Availability available={getAvailable(weekday)} start={weekday} end={weekend} />
                    <Availability
                      isWeekend
                      available={getAvailable(weekend, true)}
                      start={weekend}
                      end={weekend.clone().add(3, "days")}
                    />
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
