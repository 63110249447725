import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer, Loading, TitleBar } from "Components";
import Body from "./LocalComponents/Body";
import DateBodySection from "./LocalComponents/DateBody/DateBodySection";
import Pictures from "./LocalComponents/Pictures";
import Carousel from "./LocalComponents/Carousel";
import CustomerComments from "./LocalComponents/CustomerComments";
import Map from "./LocalComponents/Map";
import BookingModal from "./LocalComponents/BookingModal";
import PicturesModal from "./LocalComponents/PicturesModal";
import { AppContext } from "Contexts/AppContext/AppContext";

function ChaletScreen() {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const allChalets = context.getData();
  const { chaletID = "All" } = useParams();
  const chaletTitle = chaletID === "All" ? "All Karam's Chalets" : `Karam's Chalet ${chaletID}`;
  const currentChalet = allChalets?.find((e) => {
    return e.key === chaletID;
  });
  useEffect(() => {
    if (!currentChalet && allChalets) {
      navigate("/");
    }
  }, [allChalets, currentChalet, navigate]);

  if (!currentChalet) {
    return <Loading />;
  }
  return (
    <div className="divFlex1">
      <TitleBar title={chaletTitle} />
      <div className="ChaletScreenContainer">
        <Body chaletID={chaletID} chaletBackground={currentChalet?.backgroundImage}>
          <DateBodySection chalet={currentChalet} isBoth={chaletID === "All"} />
          <Pictures properties={currentChalet?.properties || []} isBoth={chaletID === "All"} />
          <Carousel items={currentChalet?.carouselImages || []} />
          <CustomerComments comments={currentChalet?.comments || []} isBoth={chaletID === "All"} />
          <Map chaletID={chaletID} map={currentChalet?.map} />
          <BookingModal currentChalet={currentChalet.name} isBoth={chaletID === "All"} />
          <PicturesModal currentChalet={currentChalet.name} isBoth={chaletID === "All"} />
        </Body>
      </div>
      <Footer whichChalet={chaletID} />
    </div>
  );
}

export default ChaletScreen;
