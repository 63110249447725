import React, { useReducer, createContext, useEffect } from "react";
import { Actions } from "./Actions.js";
import { reducer } from "./Reducer";
import { initState } from "./InitialState";
import api from "Api";
export const AppContext = createContext({
  fetchPersonalData: (callback) => {},
  fetchMembersList: (callback) => {},
  fetchNotificationList: (callback) => {},
  isLoading: () => Math.random().toString(36) === "",
  clearErrors: () => {},
  getError: () => {},
  getErrorTitle: () => null,
  verifyUserToken: (callback) => {},
  getMembersList: (callback) => [],
  getNotificationList: (callback) => [],
  getPersonalData: (callback) => {
    return {};
  },
  memberPaymentAction: (item, callback) => {},
  getData: () => {},
  getContactsData: () => {},
  setAlertData: (data) => {},
  getAlertData: () => {},
  setShowPicturesModal: (data) => {},
  getShowPicturesModal: () => {},
});

export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const isLoading = () => {
    return state.isLoading;
  };
  const setIsLoading = (payloadValue) => {
    dispatch({ type: Actions.SET_IS_LOADING, payload: payloadValue });
  };

  const clearErrors = () => {
    setError(null);
  };
  // const setApiError = (payloadValue) => {
  //   dispatch({ type: Actions.SET_API_ERROR, payload: payloadValue });
  // };

  const setError = (payloadValue) => {
    dispatch({ type: Actions.SET_ERROR, payload: payloadValue });
  };
  const getError = () => {
    return state.errorMessage;
  };
  const getErrorTitle = () => {
    return state.errorTitle;
  };

  const verifyUserToken = (callBack) => {
    setIsLoading(true);
    api
      .get("/auth/verify")
      .then((response) => {
        const data = response.data;
        if (data.isSuccess) {
          // setUserData(data.tokenData);
        }
      })
      .catch((error) => {
        // printApiError(error.message);
      })
      .finally(() => {
        callBack();
      });
  };

  const fetchMembersList = (callback) => {
    setIsLoading(true);
    api
      .get("/app/list/members")
      .then((response) => {
        // printApiResponse(response.data);
        const data = response.data;
        if (data.error.isSuccess) {
          setMembersList(data.data);
        } else {
          setError(data.error);
        }
      })
      .catch((error) => {
        const data = {
          title: "Request error",
          message: error.message,
        };
        setError(data);
      })
      .finally(() => {
        setIsLoading(false);
        callback?.();
      });
  };

  const fetchPersonalData = (callback) => {
    setIsLoading(true);
    api
      .get("/app/list/personal/notifications")
      .then((response) => {
        // printApiResponse(response.data);
        const data = response.data;
        if (data.error.isSuccess) {
          setPersonalData(data.data);
        } else {
          setError(data.error);
        }
      })
      .catch((error) => {
        const data = {
          title: "Request error",
          message: error.message,
        };
        setError(data);
      })
      .finally(() => {
        setIsLoading(false);
        callback?.();
      });
  };

  const fetchNotificationList = (callback) => {
    setIsLoading(true);
    api
      .get("/app/list/notifications")
      .then((response) => {
        const data = response.data;
        if (data.error.isSuccess) {
          setNotificationList(data.data);
        } else {
          setError(data.error);
        }
      })
      .catch((error) => {
        const data = {
          title: "Request error",
          message: error.message,
        };
        setError(data);
      })
      .finally(() => {
        setIsLoading(false);
        callback?.();
      });
  };

  const setMembersList = (payloadValue) => {
    dispatch({ type: Actions.SET_MEMBERS_LIST, payload: payloadValue });
  };
  const setPersonalData = (payloadValue) => {
    dispatch({ type: Actions.SET_PERSONAL_DATA, payload: payloadValue });
  };
  const setContactsData = (payloadValue) => {
    dispatch({ type: Actions.SET_CONTACTS_DATA, payload: payloadValue });
  };

  const setNotificationList = (payloadValue) => {
    dispatch({ type: Actions.SET_NOTIFICATION_LIST, payload: payloadValue });
  };

  const getMembersList = () => {
    return state.members;
  };
  const getNotificationList = () => {
    return state.notifications;
  };
  const getPersonalData = () => {
    return state.personalData;
  };

  const memberPaymentAction = (payload, callback) => {
    setIsLoading(true);

    api
      .post("/app/pay/fees", payload)
      .then((response) => {
        const data = response.data;
        if (data.error.isSuccess) {
          fetchMembersList(() => {
            fetchNotificationList(() => {
              fetchPersonalData(() => {
                setIsLoading(false);
              });
            });
          });
        } else {
          setError(data.error);
        }
      })
      .catch((error) => {
        // printApiError(error.message);
        const data = {
          title: "Request error",
          message: error.message,
        };
        setError(data);
        setIsLoading(false);
      })
      .finally(() => {
        callback?.();
      });
  };

  const getData = () => {
    return state.data;
  };

  const getContactsData = () => {
    return state.contactsData;
  };
  const setData = (payloadValue) => {
    dispatch({ type: Actions.SET_DATA, payload: payloadValue });
  };

  const setAlertData = (payloadValue) => {
    dispatch({ type: Actions.SET_ALERT_DATA, payload: payloadValue });
  };
  const getAlertData = () => {
    return state.alert;
  };
  const setShowPicturesModal = (payloadValue) => {
    dispatch({ type: Actions.SET_SHOW_PICTURES_MODAL, payload: payloadValue });
  };
  const getShowPicturesModal = () => {
    return state.picturesModal;
  };

  const fetchData = () => {
    api
      .get("/view")
      .then((e) => {
        setData(e.data);
      })
      .catch((e) => {});
  };
  useEffect(fetchData, []);

  const fetchContactsList = (callback) => {
    api
    .get("/contacts")
    .then((e) => {
      setContactsData(e.data);
    })
    .catch((e) => {});

  };


  useEffect(fetchContactsList, []);
  return (
    <AppContext.Provider
      value={{
        isLoading,
        clearErrors,
        getError,
        getErrorTitle,
        verifyUserToken,
        getMembersList,
        getNotificationList,
        memberPaymentAction,
        fetchMembersList,
        fetchNotificationList,
        fetchPersonalData,
        getPersonalData,
        getData,
        getAlertData,
        setAlertData,
        setShowPicturesModal,
        getShowPicturesModal,
        getContactsData,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
