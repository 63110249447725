import React, { useContext } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "Contexts/AppContext/AppContext";
const PictureButton = ({ name, onClick }) => {
  return (
    <Col>
      <Card color="secondary" className="pictureButton" onClick={onClick}>
        {name}
      </Card>
    </Col>
  );
};
const Pictures = ({ properties, isBoth }) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const { chaletID } = useParams();
  return (
    <div className="ImagesContainer">
      <Card color="secondary">
        <CardBody>
          <h3 className="title">
            Chalet Pictures
            <br />
            صور الشاليه
          </h3>
        </CardBody>
      </Card>
      <Row className="picturesContainer">
        {properties.map((e) => {
          var name = e;
          var key = e;
          if (isBoth) {
            const chalet = context.getData().filter((c) => {
              return c.key === e;
            })[0];
            name = chalet.name;
            key = chalet.key;
          }
          return (
            <PictureButton
              key={e}
              name={name}
              onClick={() => {
                if (isBoth) {
                  context.setShowPicturesModal(key);
                } else {
                  navigate(`/Pictures/${chaletID}/${e}`);
                }
              }}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default Pictures;
