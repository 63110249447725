import React, { useState, useCallback, useEffect } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { useParams } from "react-router-dom";
import api from "Api";
import { Loading } from "Components";

const SurveyScreen = () => {
  const [showPage, setShowPage] = useState(true);
  const [json, setJson] = useState({});
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(true);
  const { surveyID = "1" } = useParams();

  useEffect(() => {
    if (surveyID === "1") {
      window.location = "/";
    }
    console.log(surveyID);
    api
      .get(`/survey/${surveyID}`)
      .then((res) => {
        setJson(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        window.location = "/";
      })
      .finally(() => {});
  }, [surveyID]);

  const submitForm = useCallback(
    (payload) => {
      api
        .post(`/survey/${surveyID}`, payload)
        .then((res) => {
          setResult(res.data);
        })
        .catch((e) => {
          console.log(e.message);
        })
        .finally(() => {
          console.log("Finally");
        });
    },
    [surveyID]
  );

  const onCompletePage = useCallback(
    (data) => {
      submitForm(data.valuesHash);
      setShowPage(!showPage);
    },
    [showPage, submitForm]
  );

  const FinalPage = () => {
    console.log(result);
    setTimeout(() => {
      window.location = "/";
    }, 3000);
    return (
      <div className="final-page">
        {result.split("\n").map((d) => (
          <h1>{d}</h1>
        ))}
      </div>
    );
  };

  React.useEffect(() => {
    const isUnicode = (str) => {
      var letters = [];
      for (var i = 0; i <= str.length; i++) {
        letters[i] = str.substring(i - 1, i);
        if (letters[i].charCodeAt() > 255) {
          return true;
        }
      }
      return false;
    };
    setTimeout(() => {
      const element = document.querySelector("textarea");
      if (element === null) return;
      element.oninput = (e) => {
        console.log(isUnicode(element.value));
        element.setAttribute("style", `direction: ${element.value && isUnicode(element.value) ? "rtl" : "ltr"}`);
      };
    }, 500);
  }, [loading]);

  if (loading) {
    console.log("HERE");
    return <Loading />;
  }
  // else if(){

  // }
  return (
    <div className="SurveyScreenContainer">
      <div className="surveyLayout">
        {showPage ? (
          <Survey.Survey
            json={json}
            onComplete={onCompletePage}
            showCompletedPage={false}
            onValidateQuestion={(s, options) => {
              if (options.name === "survey") {
                json.elements.forEach((element) => {
                  console.log(element.type);
                  if (element.type === "matrix") {
                    if (!s.valuesHash.survey || element.rows.length !== Object.keys(s.valuesHash.survey).length)
                      options.error = " err";
                  }
                });
              }
              console.log("i", options);
            }}
          />
        ) : (
          <FinalPage />
        )}
      </div>
    </div>
  );
};

export default SurveyScreen;
