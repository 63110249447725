import React from "react";
import { Row, Col } from "reactstrap";
const DateControllerWithTitle = ({ dateController }) => {
  return (
    <Row className="DateControllerWithTitleContainer g-0">
      <Col lg="1" md="1" sm="1" xs="1" className="left ">
        <i
          className="fa fa-arrow-left left"
          aria-hidden="true"
          onClick={() => {
            dateController.decrement();
          }}
        />
      </Col>
      <Col lg="10" md="10" sm="10" xs="10">
        <p className="title">{dateController.theDate.format("MMM YYYY")}</p>
      </Col>
      <Col lg="1" md="1" sm="1" xs="1" className="right">
        <i
          className="fa fa-arrow-right right"
          aria-hidden="true"
          onClick={() => {
            dateController.increment();
          }}
        />
      </Col>
    </Row>
  );
};

export default DateControllerWithTitle;
