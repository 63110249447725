import instance from "./apiConfiguration";
// import { STORAGE_AUTHORIZATION_TOKEN } from "Constants";
// import AsyncStorage from "@react-native-async-storage/async-storage";

instance.interceptors.response.use(
  function (response) {
    // printApiResponse("Response Interceptor Success");
    // const data = response.data;
    // if (data.token != null) {
    //   AsyncStorage.setItem(STORAGE_AUTHORIZATION_TOKEN, data.token);
    // }
    return response;
  },
  function (error) {
    // printApiError("Response Interceptor Error", error);
    return Promise.reject(error);
  }
);
