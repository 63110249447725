import { Actions } from "./Actions.js";

/**
 * @param {any} state
 * @param {{ type: any; payload: any; }} action
 */
export function reducer(state, action) {
  switch (action.type) {
    case Actions.SET_ALERT_DATA: {
      return {
        ...state,
        alert: action.payload,
      };
    }
    case Actions.SET_SHOW_PICTURES_MODAL: {
      return {
        ...state,
        picturesModal: action.payload,
      };
    }
    case Actions.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Actions.SET_USER_DATA: {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case Actions.SET_PERSONAL_DATA: {
      return {
        ...state,
        personalData: action.payload,
      };
    }
    case Actions.SET_CONTACTS_DATA: {
      return {
        ...state,
        contactsData: action.payload,
      };
    }
    case Actions.SET_MEMBERS_LIST: {
      return {
        ...state,
        members: action.payload,
      };
    }
    case Actions.SET_NOTIFICATION_LIST: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case Actions.SET_THEME_MODE: {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case Actions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case Actions.SET_API_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.SET_ERROR: {
      return {
        ...state,
        errorMessage: action.payload?.message ?? null,
        errorTitle: action.payload?.title ?? null,
      };
    }
    default:
      return state;
  }
}
