import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { AppContext } from "Contexts/AppContext/AppContext";
function BookingModal({ currentChalet }) {
  const context = useContext(AppContext);
  const dismiss = () => {
    context.setAlertData(null);
  };
  const handleChaletClick = (property) => {
    const chalet = context.getAlertData()?.chaletName || currentChalet;
    const msg = `السلام عليكم \n \
بغيت احجز ${chalet} ${property} \n\
بالفتره التالية: \n \
من تاريخ ${context.getAlertData()?.fromDate} \n \
الى تاريخ ${context.getAlertData()?.toDate}`;

    const number = "65953967";
    const url = `https://wa.me/965${number}?text=${encodeURI(msg)}`;
    window.location = url;
    dismiss();
  };
  return (
    <Modal isOpen={context.getAlertData() != null} centered toggle={dismiss}>
      <ModalHeader toggle={dismiss}>Choose Chalet</ModalHeader>
      <ModalBody>From: {context.getAlertData()?.fromDate}</ModalBody>
      <ModalBody>To: {context.getAlertData()?.toDate}</ModalBody>
      <ModalFooter>
        {context.getAlertData()?.properties?.map((property) => {
          return (
            <Button
              key={(context.getAlertData()?.chaletName || currentChalet) + property}
              color="secondary"
              onClick={() => handleChaletClick(property)}
              style={{ color: "white", flex: 1 }}
            >
              {property}
            </Button>
          );
        })}
      </ModalFooter>
    </Modal>
  );
}

export default BookingModal;
