import React from "react";

function Loading() {
  return (
    <div className="LoadingContainer">
      <div className="loader"></div>
      {/* <h1>Loading...</h1> */}
    </div>
  );
}

export { Loading };
