import { isDevelopment } from "Constants";
import { printApiResponse, printApiError, printApiFinally, printTodo, printPageName, printTabName } from "Constants";
const dataToString = (data) => {
  var str = "";
  data.forEach((e) => {
    str += e + " ";
  });
  return str;
};
//Api Logs
global.printApiResponse = (...data) => {
  if (isDevelopment && printApiResponse) {
    console.log(`%c Response Data: ${dataToString(data)}`, "color:green");
    // console.log(terminalColor + "Response Data: ", ...data, resetColor);
  }
};

global.printApiError = (...data) => {
  // const terminalColor = "\u001b[1;31m";
  if (isDevelopment && printApiError) {
    console.log(`%c Api Error: ${dataToString(data)}`, "color:red");
    // console.log(terminalColor + "Api Error: ", ...data, resetColor);
  }
};

global.printApiFinally = (...data) => {
  // const terminalColor = "\u001b[1;32m";
  if (isDevelopment && printApiFinally) {
    console.log(`%c Finally: ${dataToString(data)}`, "color:purple");
    // console.log(terminalColor + "Finally: ", ...data, resetColor);
  }
};

global.printTodo = (...data) => {
  // const terminalColor = "\u001b[1;33m";
  if (isDevelopment && printTodo) {
    console.log(`%c TODO: ${dataToString(data)}`, "color:yellow");
    // console.log(terminalColor + "TODO: ", ...data, resetColor);
  }
};

global.printPageName = (...data) => {
  // const terminalColor = "\u001b[1;36m";
  if (isDevelopment && printPageName) {
    console.log(`%c Screen name: ${dataToString(data)}`, "color:cyan");
    // console.log(terminalColor + "Screen name: ", ...data, resetColor);
  }
};

global.printTabName = (...data) => {
  // const terminalColor = "\u001b[1;35m";
  if (isDevelopment && printTabName) {
    console.log(`%c Screen name: ${dataToString(data)}`, "color:purple");
  }
};

global.log = (...data) => {
  console.log(...data, resetColor);
};

const resetColor = "\u001b[0m";
