import React from "react";
import "./index.css";
import App from "./App";
import ReactDomClient from "react-dom/client";

const Root = () => {
  return (
    <React.Fragment>
      <App />
    </React.Fragment>
  );
};

const container = document.getElementById("root");
const root = ReactDomClient.createRoot(container);
root.render(<Root />);
