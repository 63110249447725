import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const Map = ({ map }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyATlLjd5FwOrdagYFnJ6nIwK6II8k7MDy0",
  });
  if (!isLoaded || !map) return <></>;

  return (
    <div style={{ height: 400, marginTop: 10 }}>
      <GoogleMap mapContainerClassName="MapContainer" zoom={map.zoom} center={map.center}>
        {map.markers.map((marker) => {
          return <Marker position={marker} key={JSON.stringify(marker)} />;
        })}
      </GoogleMap>
    </div>
  );
};

export default Map;
