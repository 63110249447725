import React, { useContext } from "react";
import { Icon } from "./Icon";
import { AppContext } from "Contexts/AppContext/AppContext";
function Footer({ whichChalet }) {
  const context = useContext(AppContext);
  const allChalets = context.getData();
  const currentChalet = allChalets?.find((e) => {
    return e.key === whichChalet;
  });
  if (currentChalet === null || currentChalet === undefined) {
    return <div className="FooterContainer" style={{ height: 66 }}></div>;
  }
  return (
    <div className="FooterContainer">
      <Icon iconClass="fa fa-whatsapp" url={`https://wa.me/965${currentChalet.bookingNumber}`} />
      <Icon iconClass="fa fa-instagram" url="https://www.instagram.com/karamchalet/" />
      <Icon iconClass="fas fa-map-marker-alt" url={currentChalet.googleMapUrl} />
    </div>
  );
}

export { Footer };
