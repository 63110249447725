import React from "react";
import { Card, CardHeader, CardImg, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
function ChaletBox({ title, dest, src, temporaryUnavailable }) {
  const navigate = useNavigate();
  return (
    <Col lg="3" md="12" sm="12" xs="12" className="ChaletBoxContainer">
      <Card
        color="secondary"
        onClick={() => {
          if (temporaryUnavailable !== 1) {
            navigate(`/Chalet/${dest}`);
          }
        }}
      >
        <CardHeader>{title}</CardHeader>
        <div>
          <CardImg
            src={src}
            style={{ padding: 10 }}
            className="CardImage"
            alt={dest}
            title={dest}
            loading="eager"
            width="100%"
            height="100%"
          />
          {temporaryUnavailable ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                flex: 1,
                backgroundColor: "#00000050",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  right: 0,
                  left: 0,
                  transform: `translate(0, -50%)`,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1>Unavailable</h1>
                <h1>غير متوفر</h1>
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </Col>
  );
}

export { ChaletBox };
