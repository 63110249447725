import React, { useContext } from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import { AppContext } from "Contexts/AppContext/AppContext";
import { useNavigate } from "react-router-dom";

function PicturesModal({ currentChalet }) {
  const context = useContext(AppContext);
  const chalet = context.getData()?.filter((e) => e.key === context.getShowPicturesModal())?.[0];
  const navigate = useNavigate();
  const dismiss = () => {
    context.setShowPicturesModal(null);
  };
  const handleChaletClick = (property) => {
    navigate(`/Pictures/${chalet.key}/${property}`);
    dismiss();
  };
  return (
    <Modal isOpen={context.getShowPicturesModal() != null} centered toggle={dismiss}>
      <ModalHeader toggle={dismiss}>Choose Chalet</ModalHeader>
      <ModalFooter>
        {chalet?.properties?.map((property) => {
          return (
            <Button color="secondary" onClick={() => handleChaletClick(property)} style={{ color: "white", flex: 1 }}>
              {property}
            </Button>
          );
        })}
      </ModalFooter>
    </Modal>
  );
}

export default PicturesModal;
