import React, { useContext } from "react";
import { ChaletBox, Footer, Loading, TitleBar } from "Components";
import { Row } from "reactstrap";
import { AppContext } from "Contexts/AppContext/AppContext";
function HomeScreen() {
  const context = useContext(AppContext);
  const allChalets = context.getData();

  if (allChalets) {
    return (
      <div style={{ flex: 1 }}>
        <TitleBar title="Karam Chalets" />
        <div className="HomeScreenContainer">
          <Row style={{ justifyContent: "center" }}>
            {allChalets.map((chalet, indx) => {
              if (indx === allChalets.length - 1) {
                const availableChalets = allChalets.filter((x) => x.temporaryUnavailable === 0);
                if (availableChalets.length <= 1) return null;
              }
              return (
                <ChaletBox
                  title={chalet.name}
                  src={chalet.image}
                  dest={chalet.key}
                  key={chalet.key}
                  temporaryUnavailable={chalet.temporaryUnavailable}
                />
              );
            })}
          </Row>
        </div>
        <Footer whichChalet={allChalets?.[allChalets?.length - 1]?.key} />
      </div>
    );
  }
  return <Loading />;
}

export default HomeScreen;
