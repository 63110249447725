import { useState, useMemo } from "react";
import moment from "moment";

function DateHook() {
  const [currentMonth, setCurrentMonth] = useState(moment().month());

  const theDate = moment().month(currentMonth);
  const firstDayOnTheCalendar = theDate.clone().startOf("month").day(0);
  var dateTracker = theDate.clone().startOf("month").day(0);
  const lastDayOnTheCalendar = theDate.clone().endOf("month").day(6);
  const numOfDaysBetweenFirstAndLastDates = (lastDayOnTheCalendar.diff(firstDayOnTheCalendar, "days") + 1) / 7;
  const today = moment();

  const increment = () => {
    setCurrentMonth(currentMonth + 1);
  };

  const decrement = () => {
    if (moment().month() - currentMonth === 0) return;
    setCurrentMonth(currentMonth - 1);
  };

  const printDateTracker = () => {
    const date = dateTracker.format("D");
    dateTracker.add(1, "days");
    return date;
  };

  const isDateOutOfRange = () => {
    const current = theDate.format("MM");
    const date = dateTracker.format("MM");
    return current !== date;
  };
  const isCurrentDate = () => {
    const current = moment().format("DD/MM");
    const date = dateTracker.format("DD/MM");
    return current === date;
  };

  const getEntryPoints = useMemo(() => {
    var start = firstDayOnTheCalendar.days(-1).clone();
    var end = lastDayOnTheCalendar;
    var day = 0; // Sunday
    var day2 = 4; // Thursday

    var result = [];
    var current = start.clone();

    while (current.day(7 + day).isBefore(end)) {
      result.push(current.clone());
    }

    current = start.clone();
    var result2 = [];
    while (current.day(7 + day2).isBefore(end)) {
      result2.push(current.clone());
    }

    var finalResult = [];
    for (let i = 0; i < result.length; i++) {
      finalResult.push({
        weekday: result[i],
        weekend: result2[i],
      });
    }

    return finalResult;
  }, [firstDayOnTheCalendar, lastDayOnTheCalendar]);

  return {
    decrement,
    increment,
    firstDayOnTheCalendar,
    lastDayOnTheCalendar,
    today,
    numOfDaysBetweenFirstAndLastDates,
    theDate,
    printDateTracker,
    isDateOutOfRange,
    isCurrentDate,
    getEntryPoints,
  };
}

export default DateHook;
